<template>
  <div id="config">
    <Headline />
    <v-sheet class="pa-6">
      <v-row>
        <v-col class="col-md-4">
          <v-select
            v-model="theme"
            label="Theme"
            :items="themes"
            hide-details
            filled
            :menu-props="{ bottom: true, offsetY: true, maxHeight: 400 }"
            @change="setTheme"
          ></v-select>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import Headline from '../../components/app/Headline'
import LocalStore from '@/util/LocalStore'

export default {
  components: {
    Headline
  },
  data() {
    return {
      theme: '',
      themes: ['new-ui', 'classic', 'dark-violet', 'cosmic-blue', 'deep-purple', 'chocolat', 'spring-forest']
    }
  },
  methods: {
    setTheme(theme) {
      window.setTheme(theme)
      LocalStore.setPcTheme(theme)
    }
  },
  mounted() {
    this.theme = LocalStore.getPcTheme()
  }
}
</script>
